import { BoxProps } from '@leagueplatform/genesis-commons';
import { DropdownSection } from '@leagueplatform/web-common-components';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

const settingsLogoutLink = {
  text: 'LOG_OUT',
  to: APP_MANAGED_ROUTES.SIGN_OUT,
  linkProps: {
    color: 'onSurface.text.critical',
  } as BoxProps,
};

export const useUserDropdownComponent = () => {
  const { data, isLoading, error } = usePreferredFirstName();

  const settingsLinks = [
    {
      text: 'PERSONAL_INFO',
      to: APP_MANAGED_ROUTES.PERSONAL_INFO,
    },
    {
      text: 'COMMUNICATION_PREFERENCES',
      to: APP_MANAGED_ROUTES.COMMUNICATION_PREFERENCES,
    },
  ];

  const desktopUserDropdownConfig: DropdownSection[] = [
    settingsLinks.map((props) => ({
      ...props,
      linkProps: {
        fontWeight: 'bold',
        color: 'onSurface.text.subdued',
        target: '_blank',
      },
    })),
    [
      {
        ...settingsLogoutLink,
        linkProps: {
          fontWeight: 'bold',
          color: 'onSurface.text.critical',
        },
      },
    ],
  ];

  const mobileNavDrawerLinksConfig = [
    {
      items: settingsLinks,
    },
    {
      items: [
        {
          ...settingsLogoutLink,
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];

  const dropdownMenuItemProps = {
    preferredFirstName: data?.preferredFirstName,
    desktopUserDropdownConfig,
    mobileNavDrawerLinksConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
