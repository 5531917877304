import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import {
  InputStatusMessage,
  StackLayout,
  HeadingText,
  Image,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { useAcceptUserTerms } from '../../hooks/use-accept-user-terms';
import {
  ProfileData,
  useSetUserProfile,
} from '../../hooks/use-set-user-profile';
import { TermAndConditionsText } from '../term-and-conditions-text/term-and-conditions-text.component';
import { FormikCheckBox } from '../form/formik-checkbox.component';
import { FixedFormFooter } from '../form/fixed-form-footer.component';

type UserSetupFormData = {
  areTermsAccepted: boolean;
  isEmailOptedIn: boolean;
};

const IS_TERMS_ACCEPTED = 'isTermsAccepted';

const handleKeyPress = (e: React.KeyboardEvent, formProps: any) => {
  if (e.key === 'Enter' && (e.target as HTMLInputElement).type === 'checkbox') {
    // this is needed to work around Formik's default of submitting on hitting enter
    e.preventDefault();
    const targetName: string = (e.target as HTMLInputElement).name;
    formProps.setFieldValue([targetName], !formProps.values[targetName]);
  }
};

const validateForm = (
  values: UserSetupFormData,
  formatMessage: (descriptor: { id: string }) => string,
) => {
  const errors: { [key: string]: any } = {};
  if (!values.areTermsAccepted) {
    errors.areTermsAccepted = formatMessage({ id: 'ERROR_CAPTION_TERMS' });
  }
  return errors;
};

export const UserSetupFormComponent = ({
  formImage,
  handleExit,
}: {
  formImage: string;
  handleExit: () => void;
}) => {
  const { formatMessage } = useIntl();
  const acceptUserTerms = useAcceptUserTerms();

  const optInEmails: ProfileData = {
    opted_into_marketing_communications: true,
  };
  const setOptInEmails = useSetUserProfile(optInEmails);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formValues: UserSetupFormData) => {
    setLoading(true);

    // show error and keep user on slide only if terms call fails, not if email opt-in profile call fails
    if (formValues.isEmailOptedIn) {
      try {
        await setOptInEmails();
      } catch {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_CAPTION_PROFILE',
        });
      }
    }

    try {
      const termsApiCall = await acceptUserTerms();
      if (termsApiCall.success) {
        setLoading(false);
        handleExit();
      } else {
        setLoading(false);
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          textId: 'ERROR_CAPTION_SAVE',
        });
      }
    } catch {
      setLoading(false);
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'ERROR_CAPTION_SAVE',
      });
    }
  };

  return loading ? (
    <StackLayout>
      <LoadingIndicator />
    </StackLayout>
  ) : (
    <StackLayout
      verticalAlignment="center"
      horizontalAlignment="center"
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
        overflow: 'auto',
      }}
    >
      <StackLayout
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          width: '360px',
        }}
      >
        {formImage && (
          <Image
            src={formImage}
            alt="Two hands facing eachother with both thumb ups and confetti around "
            width={176}
            css={{ marginBottom: '$two' }}
          />
        )}
        <HeadingText level="2" size="xl" css={{ marginBottom: '$half' }}>
          {formatMessage({ id: 'ACCOUNT_SETUP_FORM_HEADING' })}
        </HeadingText>
        <Formik
          initialValues={{ areTermsAccepted: false, isEmailOptedIn: false }}
          onSubmit={(formProps) => handleSubmit(formProps)}
          validate={(values: UserSetupFormData) =>
            validateForm(values, formatMessage)
          }
          validateOnBlur={false}
          validateOnChange
        >
          {(formProps) => (
            <Form
              onKeyPress={(e: React.KeyboardEvent) =>
                handleKeyPress(e, formProps)
              }
            >
              <FormikCheckBox
                fieldId="terms"
                fieldName="areTermsAccepted"
                fieldBody={<TermAndConditionsText />}
                formErrors={formProps.errors}
                css={{
                  marginTop: '$one',
                  marginBottom: formProps.errors.areTermsAccepted
                    ? '$half'
                    : '$twoAndHalf',
                }}
              />
              {formProps.errors.areTermsAccepted && (
                <InputStatusMessage
                  inputStatus="error"
                  hideIcon
                  id={`${IS_TERMS_ACCEPTED}-error`}
                  css={{
                    marginLeft: '$two',
                    marginBottom: '$one',
                  }}
                >
                  {formatMessage({ id: 'ERROR_CAPTION_TERMS' })}
                </InputStatusMessage>
              )}
              <FormikCheckBox
                fieldId="email"
                fieldName="isEmailOptedIn"
                fieldBody={formatMessage({
                  id: 'COMMUNICATION_PREFERENCES_INITIAL_ALLOW_EMAIL',
                })}
                formErrors={formProps.errors}
              />
              <FixedFormFooter
                allowSubmit={!!formProps?.values?.areTermsAccepted}
                isLoading={formProps.isSubmitting}
                submitText={formatMessage({ id: 'CONTINUE' })}
              />
            </Form>
          )}
        </Formik>
      </StackLayout>
    </StackLayout>
  );
};
