import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  MainPageContainer,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import {
  Box,
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { inputFieldData } from 'utils/input-field-data';
import {
  usePersonalInformation,
  UserProfileForm,
  EditProfilePhoto,
  EditDisplayName,
  FixedFormFooter,
} from '@xo-health-web/shared-module';

export const PersonalInformationPage = ({
  showPersonalInformation,
  handleInfoExit,
}: {
  showPersonalInformation: boolean;
  handleInfoExit: () => void;
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'PERSONAL_INFO' }));

  const { data, isLoading } = usePersonalInformation();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <MainPageContainer width="100%">
      {showPersonalInformation ? (
        <Box>
          <StackLayout horizontalAlignment="center" css={{ height: '30%' }}>
            <HeadingText level="2" size="xl" css={{ marginBottom: '$two' }}>
              {formatMessage({ id: 'JUST_A_COUPLE_MORE_STEPS' })}
            </HeadingText>
            <Box
              css={{
                width: '100%',
                maxWidth: 370,
              }}
            >
              <EditProfilePhoto />
              <EditDisplayName />
              <Box>
                <ParagraphText size="xs" css={{ marginTop: '$two' }}>
                  {formatMessage({ id: 'CONTACT_CARE_ADVOCATE' })}
                </ParagraphText>
                <UserProfileForm data={data} inputFieldData={inputFieldData} />
              </Box>
              <FixedFormFooter
                allowSubmit={showPersonalInformation}
                handleInfoExit={handleInfoExit}
                submitText={formatMessage({ id: 'CONTINUE' })}
              />
            </Box>
          </StackLayout>
        </Box>
      ) : (
        <Box>
          <HeroSection
            title={formatMessage({ id: 'PERSONAL_INFO' })}
            goBack={history.goBack}
          />
          <StackLayout horizontalAlignment="center">
            <Box
              css={{
                width: '100%',
                maxWidth: 370,
              }}
            >
              <EditProfilePhoto />
              <EditDisplayName />
              <ParagraphText size="sm">
                {formatMessage({ id: 'CONTACT_CARE_ADVOCATE' })}
              </ParagraphText>
              <UserProfileForm data={data} inputFieldData={inputFieldData} />
            </Box>
          </StackLayout>
        </Box>
      )}
    </MainPageContainer>
  );
};
