import { useEffect, useState } from 'react';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { UserSetupFormComponent } from '@xo-health-web/shared-module';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { BasePage } from 'components/BasePage';
import { OnboardingFeatureHighlights } from 'components/onboarding-feature-highlights/onboarding-feature-highlights.component';
import completion from 'assets/completion.svg';
import { useOnboarding } from 'hooks/use-onboarding.hook';
import { PersonalInformationPage } from 'pages/PersonalInformationPage';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    data: userData,
    isLoading: isLoadingUserProfile,
    isError: isErrorUserProfile,
  } = useGetUserProfile();
  const [termsFinished, setTermsFinished] = useState<boolean>(false);
  const [showPersonalInformation, setShowPersonalInformation] =
    useState<boolean>(false);

  const handleInfoExit = () => {
    setShowPersonalInformation(false);
  };

  const { onOnboardingComplete, shouldShowFeatureHighlights, isLoading } =
    useOnboarding();

  useEffect(() => {
    const hasUserAcceptedTerms =
      userData?.userProfile?.memberTermsAndConditions?.dateAccepted;

    if (hasUserAcceptedTerms) {
      setTermsFinished(true);
    }
  }, [userData]);

  if (isErrorUserProfile) return <ErrorState />;

  const handleExit = () => {
    setTermsFinished(true);
    setShowPersonalInformation(true);
  };

  if (isLoading || isLoadingUserProfile) {
    return <LoadingIndicator />;
  }
  if (shouldShowFeatureHighlights) {
    return (
      <OnboardingFeatureHighlights
        onOnboardingComplete={onOnboardingComplete}
      />
    );
  }

  if (!termsFinished) {
    return (
      <UserSetupFormComponent formImage={completion} handleExit={handleExit} />
    );
  }
  if (showPersonalInformation) {
    return (
      <PersonalInformationPage
        showPersonalInformation={showPersonalInformation}
        handleInfoExit={handleInfoExit}
      />
    );
  }
  return <BasePage>{children}</BasePage>;
};
